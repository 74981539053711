import dayjs from 'dayjs';
import {camelizeKeys} from 'humps';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {loadDailiesIssues} from 'modules/Tasks/components/Gantt/utils/load';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {IssueModelDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

const filterClosedStatus = (issue: IssueModelDTO) => issue.status !== TaskStatusType.closed;

export function useFetchDailiesIssues() {
  const {projectId} = useParams<{projectId: string}>();
  const {queryParams} = useFilterContext();

  const selectedDay = dayjs(queryParams.schedEndFirst).toISOString();
  const queryKey = [QUERY_CACHE_KEYS.dailiesIssues, projectId, selectedDay, JSON.stringify(queryParams)];

  const {data: res, isLoading} = useQuery(queryKey, () => loadDailiesIssues(projectId, queryParams), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const issues = res ? (camelizeKeys(res) as IssueModelDTO[]).filter(filterClosedStatus) : [];
  return {issues, isLoading};
}
