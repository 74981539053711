import dayjs from 'dayjs';
import {camelize} from 'humps';

import {completionUnitLabels} from 'shared/constants/completionUnits';
import {FeedbackDailyTypeMap} from 'shared/models/feedback';
import {TaskCompletionUnits, FeedbackByDateTask} from 'shared/models/task/task';

import {DailyTask, FeedbackRecord, ProcessedEntry} from '../types';

export function formatProgressUnits({
  progressComplete,
  completionTarget,
  completionUnit,
}: {
  progressComplete: string;
  completionTarget: string;
  completionUnit: TaskCompletionUnits;
}) {
  // Set default values if input is undefined or empty. Notice the parentheses, that guarantee correct evaluation order.
  const formattedCompletionUnit = (completionUnit?.trim() !== '' ? completionUnit : '') ?? '-';
  const formattedProgressComplete = progressComplete ?? 0;
  const formattedCompletionTarget = (completionTarget?.trim() !== '' ? completionTarget : '0') ?? '0';

  // If completionUnitLabels return `undefined` or empty, use "" as a fallback
  let unitLabel = '';
  if (formattedCompletionUnit in completionUnitLabels && completionUnitLabels[formattedCompletionUnit].trim() !== '') {
    unitLabel = completionUnitLabels[formattedCompletionUnit];
  }

  return {formattedProgressComplete, formattedCompletionTarget, unitLabel};
}

export function processReportsData(feebackByDate: FeedbackByDateTask[]): ProcessedEntry[] {
  const dateEntries = {};

  feebackByDate?.forEach((entry) => {
    const casedField = camelize(entry.field);
    entry.feedbackByDate.forEach((feedback) => {
      if (!dateEntries[feedback.timeCreated]) {
        dateEntries[feedback.timeCreated] = {};
      }

      dateEntries[feedback.timeCreated][casedField] = {
        value: feedback.value,
        workerId: feedback.workerId,
        timeCreated: feedback.timeCreated,
        date: feedback.date,
      };
    });
  });

  const sortedEntries = Object.entries<FeedbackRecord>(dateEntries)
    .sort((a, b) => dayjs(a[0]).unix() - dayjs(b[0]).unix())
    .map(([date, entries]) => ({
      date,
      ...entries,
    })) as ProcessedEntry[];

  return sortedEntries;
}

export function taskHasFeedback(task: DailyTask) {
  return task?.feedbackByDate[0]?.feedbackByDate.length > 0 || task?.feedbackByDate[1]?.feedbackByDate.length > 0;
}

export function countActivitiesAndReports(reports: DailyTask[]) {
  if (!reports?.length) {
    return {totalActivities: 0, totalReportedProgress: 0, totalReportedManpower: 0};
  }

  const totalActivities = reports.length;
  let totalReportedProgress = 0;
  let totalReportedManpower = 0;
  const uniqueEntries = new Set<string>();

  for (const report of reports) {
    if (!taskHasFeedback(report)) {
      continue;
    }

    for (const feedback of report.feedbackByDate) {
      const isLabor = feedback.field === FeedbackDailyTypeMap.DailyLabor;

      for (const innerFeedback of feedback.feedbackByDate) {
        const uniqueKey = `${innerFeedback.date}-${innerFeedback.timeCreated}-${innerFeedback.workerId}`;

        if (!uniqueEntries.has(uniqueKey)) {
          uniqueEntries.add(uniqueKey);
          totalReportedProgress += 1;

          if (isLabor) {
            totalReportedManpower += Number(innerFeedback.value);
          }
        }
      }
    }
  }

  return {
    totalActivities,
    totalReportedProgress,
    totalReportedManpower,
  };
}
