import cn from 'classnames';
import {decamelize} from 'humps';
import {FC, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';
import {useTable, Column, Row} from 'react-table';
import {TableVirtuoso} from 'react-virtuoso';

import CompanyApi from 'api/company';
import Button from 'shared/components/Button';
import {useConfirm} from 'shared/components/Confirmation';
import Icon from 'shared/components/Icon';
import Popup from 'shared/components/Popup/Popup';
import ProgressBar from 'shared/components/ProgressBar';
import LegendCompare from 'shared/components/TasksImport/components/LegendCompare';
import s from 'shared/components/TasksImport/components/styles.module.scss';
import Tooltip from 'shared/components/Tooltip';
import {mixpanelEvents} from 'shared/constants/mixpanelEvents';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';

import {useTasksImportContext} from '../TasksImportContext/TasksImportContext';
import {getTableCellClass, statusSuffix} from '../utils/constants';
import {TaskImportField, TaskImportObject, TaskImportResult} from '../utils/types';

const TABLE_HEIGHT = 425;

type TasksImportPreviewProps = {
  fields: TaskImportField[];
  importPercentage: number;
  onBack: () => void;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  result: TaskImportResult;
};

const TasksImportPreviewTable: FC<TasksImportPreviewProps> = ({
  fields,
  importPercentage,
  onBack,
  onClose,
  onSubmit,
  result,
}) => {
  const {confirm} = useConfirm();
  const {t} = useTranslation('import');
  const {projectId} = useParams<{projectId: string}>();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId}});
  const [{config}] = useTasksImportContext();
  const company = useCompany();
  const [importInProcess, setImportInProcess] = useState(false);
  const [subcontractorOptions, setSubcontractorOptions] = useState({});
  const [showOnlyErrors, setShowOnlyErrors] = useState(false);

  const handleSubmit = async () => {
    try {
      if (result.errors?.find((error) => error.code === 'missing_outline_parent')) {
        if (
          await confirm({
            description: t('import_preview.description'),
            cancelButton: t('import_preview.buttons.cancel'),
            acceptButton: t('import_preview.buttons.continue'),
          })
        ) {
          setImportInProcess(true);
          await onSubmit();
          setImportInProcess(false);
        } else {
          return onClose();
        }
      } else {
        setImportInProcess(true);
        await onSubmit();
        setImportInProcess(false);
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setImportInProcess(false);
    }
  };

  const whichMixPanelEvent = showOnlyErrors
    ? mixpanelEvents.tasks.import.showAllRows
    : mixpanelEvents.tasks.import.showMoreErrors;

  const toggleView = () => {
    mixpanel.trackWithAction(() => setShowOnlyErrors(!showOnlyErrors), whichMixPanelEvent);
  };

  useQuery(
    ['companySubcontractorsImport', company?.id],
    () => {
      return CompanyApi.getCompanyOrgs(company.id).then((res) => {
        const preparedOptions = res.reduce((acc, sub) => {
          acc[sub.id] = sub.group.name;
          return acc;
        }, {});
        setSubcontractorOptions(preparedOptions);
      });
    },
    {enabled: !!company?.id, refetchOnWindowFocus: false},
  );

  const taskResponses = useMemo(
    () => (showOnlyErrors ? result.taskResponses.filter((task) => !!task?.errors.length) : result.taskResponses),
    [result.taskResponses, showOnlyErrors],
  );

  const columns = useMemo(() => {
    return fields.map(
      (field): Column<TaskImportObject> => ({
        Header: field.columnTitle || field.label,
        id: field.key || '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: ({row}: {row: Row<TaskImportObject>}) => {
          const taskCompare = row.original;
          const isEdit = taskCompare.updatedFields.includes(decamelize(field.key || ''));
          const isError = taskCompare.errors.find((error) => error.field === decamelize(field.key || ''));
          const cellClassName = isError ? 'error' : isEdit ? 'edit' : '';

          let value;
          if (field.key === 'assignedNames') {
            value = taskCompare.nameResolutions;
          } else if (field.key === 'subcontractor') {
            value = subcontractorOptions[taskCompare.task.responsibleOrgId] || '';
          } else if (field.key) {
            value = taskCompare.task[field.key];
          }

          const displayValue =
            field.accessor && value !== undefined
              ? field.key === 'assignedNames'
                ? field.accessor(value, taskCompare.nameResolutions, config)
                : field.accessor(value, null, config)
              : value;

          return (
            <span
              className={cn(
                'aka-table__value',
                'aka-table__value--one-line',
                cellClassName ? `aka-table__value--${cellClassName}` : '',
                {
                  'aka-table__value--id': field.key === 'uniqueId',
                },
              )}
            >
              {displayValue}
              {(isEdit || isError) && (
                <Tooltip
                  text={isEdit ? t('import_preview.tooltip.text') : isError.messages.join(', ')}
                  type={isEdit ? 'edit' : 'error'}
                  placement="top"
                >
                  <Button
                    className="aka-table__value-button"
                    iconOnly
                    icon={<Icon name={isEdit ? 'swap_horizontal' : isError && 'error'} colorFill />}
                  >
                    {t('import_preview.buttons.show')}
                  </Button>
                </Tooltip>
              )}
            </span>
          );
        },
      }),
    );
  }, [fields, subcontractorOptions, config, t]);

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data: taskResponses,
  });

  const headerContent = () => (
    <div className={s.tableHeader}>
      {headerGroups.map((headerGroup, idx) => (
        <div {...headerGroup.getHeaderGroupProps()} key={`header-${idx}`} className="aka-table__tr">
          {headerGroup.headers.map((column) => (
            <div {...column.getHeaderProps()} key={`header-col-${column.id}`} className={getTableCellClass(column.id)}>
              <span className="aka-table__value aka-table__value--one-line">{column.render('Header')}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const rowContent = (index: number) => {
    const row = rows[index];
    prepareRow(row);

    return (
      <div
        {...row.getRowProps()}
        style={{
          display: 'flex',
        }}
        className={cn('aka-table__tr', `aka-table__tr--${statusSuffix[row.original.status]}`)}
      >
        {row.cells.map((cell) => (
          <div {...cell.getCellProps()} key={cell.column.id} className={getTableCellClass(cell.column.id)}>
            {cell.render('Cell')}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Popup.Body className={s.tableScrollContainer}>
        <div {...getTableProps()} className="aka-table aka-table--import">
          <div {...getTableBodyProps()} className={s.tableBody}>
            <TableVirtuoso
              itemContent={rowContent}
              fixedHeaderContent={headerContent}
              totalCount={rows.length}
              style={{height: TABLE_HEIGHT}}
            />
          </div>
        </div>
      </Popup.Body>
      <Popup.Footer>
        <Button
          className="ctrl-btn--color-light ctrl-btn--shadow popup__button popup__button--back"
          icon={<Icon colorFill name="arrow_backward" />}
          onClick={onBack}
        >
          {t('import_preview.buttons.back')}
        </Button>
        {importInProcess ? (
          <div className={s.progressbar__container}>
            <ProgressBar progress={importPercentage} />
          </div>
        ) : (
          <LegendCompare
            className="popup__legend"
            showErrors={showOnlyErrors}
            toggleErrors={toggleView}
            totals={{
              unChanged: result.unchangedCount,
              new: result.addedCount,
              edited: result.updatedCount,
              error: result.taskResponses.filter((taskResponse) => taskResponse.errors.length).length,
            }}
          />
        )}
        <Button
          data-cy="btnImportFinish"
          icon={importInProcess ? <Icon colorFill className="ctrl-btn__icon" name="autorenew" /> : null}
          className={`popup__button ${importInProcess ? 'is-processing' : 'ctrl-btn--color-success'}`}
          type="button"
          onClick={handleSubmit}
          disabled={importInProcess}
        >
          {importInProcess ? t('import_preview.buttons.importing') : t('import_preview.buttons.finish')}
        </Button>
      </Popup.Footer>
    </>
  );
};

export default TasksImportPreviewTable;
