/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import {Effect, Layer} from 'effect';
import {InterruptedException} from 'effect/Cause';

import {LoaderOptions} from 'modules/Tasks/components/Gantt/utils/load';

import {EffectError} from '../errors';

export const calculateBatchParameters = (total: number, configuredBatchSize?: number) => {
  // Handle negative numbers and ensure total is non-negative
  const safeTotal = Math.max(0, total);

  // Handle invalid configuredBatchSize
  const safeBatchSize = configuredBatchSize && configuredBatchSize > 0 ? configuredBatchSize : 1_000;

  // For very small datasets, just load everything in one batch
  if (safeTotal <= 100) {
    return {
      batchSize: safeTotal,
      batchCount: safeTotal === 0 ? 1 : Math.ceil(safeTotal / safeTotal),
      concurrency: 1,
    };
  }

  // For small datasets (101-500 items), use 2 batches
  if (safeTotal <= 500) {
    const batchSize = Math.ceil(safeTotal / 2);
    return {
      batchSize,
      batchCount: Math.ceil(safeTotal / batchSize),
      concurrency: 2,
    };
  }

  // For medium datasets (501-750), use 3 batches
  if (safeTotal <= 750) {
    const batchSize = Math.ceil(safeTotal / 3);
    return {
      batchSize,
      batchCount: Math.ceil(safeTotal / batchSize),
      concurrency: 3,
    };
  }

  // For larger datasets (751-2500), use 5 batches
  if (safeTotal <= 2_500) {
    const batchSize = Math.ceil(safeTotal / 5);
    return {
      batchSize,
      batchCount: Math.ceil(safeTotal / batchSize),
      concurrency: 5,
    };
  }

  // For very large datasets (2500-5000), use 10 batches
  if (safeTotal <= 5_000) {
    const batchSize = Math.ceil(safeTotal / 10);
    return {
      batchSize,
      batchCount: Math.ceil(safeTotal / batchSize),
      concurrency: 10,
    };
  }

  const IDEAL_BATCH_SIZE = safeBatchSize;
  const MAX_CONCURRENT_REQUESTS = 10;
  // Calculate number of batches needed with ideal batch size
  const batchCount = Math.ceil(safeTotal / IDEAL_BATCH_SIZE);
  // Adjust concurrency based on batch count
  const concurrency = Math.min(batchCount, MAX_CONCURRENT_REQUESTS);

  return {
    batchSize: IDEAL_BATCH_SIZE,
    batchCount,
    concurrency,
  };
};

export const formatTime = (ms: number) => {
  const MILLISECONDS_IN_SECOND = 1_000;
  const MILLISECONDS_IN_MINUTE = 60_000;
  const DEFAULT_TIME = '0ms';
  const DECIMAL_PLACES = 1;
  const INTEGER_PLACES = 0;

  if (!ms || Number.isNaN(ms)) {
    return DEFAULT_TIME;
  }

  // Less than a second
  if (ms < MILLISECONDS_IN_SECOND) {
    return `${ms.toFixed(INTEGER_PLACES)}ms`;
  }

  // Less than a minute
  if (ms < MILLISECONDS_IN_MINUTE) {
    return `${(ms / MILLISECONDS_IN_SECOND).toFixed(DECIMAL_PLACES)}s`;
  }

  // Minutes and seconds
  const minutes = Math.floor(ms / MILLISECONDS_IN_MINUTE);
  const seconds = ((ms % MILLISECONDS_IN_MINUTE) / MILLISECONDS_IN_SECOND).toFixed(DECIMAL_PLACES);
  return `${minutes}m ${seconds}s`;
};

export async function runLoaderEffect<T extends InterruptedException | unknown[], E extends EffectError, R>(
  program: Effect.Effect<T, E, R>,
  provider: Layer.Layer<R, never, never>,
  options: LoaderOptions,
): Promise<T | undefined> {
  if (!options.name || !options.projectId) {
    throw new Error('Missing required options: name and projectId are required');
  }

  const runnable = program.pipe(
    Effect.provide(provider),
    Effect.catchAll((error: E) => {
      if (error instanceof InterruptedException) {
        return Effect.succeed(new InterruptedException('InterruptedException') as T);
      }
      return Effect.fail(error);
    }),
  );

  try {
    const result = await Effect.runPromise(runnable);
    return result;
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        projectId: options.projectId,
        name: options.name,
      },
      extra: options.extra,
    });
  }
}

export const createProjectLoader = <T>(
  projectId: string,
  loaderFn: Effect.Effect<T, EffectError, never>,
): Effect.Effect<T, EffectError, never> => {
  return Effect.gen(function* (_) {
    // eslint-disable-next-line import/no-named-as-default-member
    const cancelTokenSource = axios.CancelToken.source();

    return yield* _(
      loaderFn.pipe(
        Effect.ensuring(
          Effect.sync(() => {
            cancelTokenSource.cancel('Project changed or operation completed');
          }),
        ),
        Effect.withSpan('projectLoader', {
          attributes: {
            projectId,
            cancelToken: cancelTokenSource.token,
          },
        }),
      ),
    );
  });
};
