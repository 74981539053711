import './styles.scss';

import {Accordion, AccordionItem} from '@szhsin/react-accordion';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import Icon from 'shared/components/Icon';
import Loader from 'shared/components/Loader';
import {VSpacer} from 'shared/components/Spacer/VSpacer';
import {IconsMap} from 'shared/constants/icons';
import {formatDate} from 'shared/helpers/dates';
import {IOC_TYPES} from 'shared/models/ioc';
import {useInjectStore} from 'shared/providers/injection';
import {UIStoreType} from 'shared/stores/UIStore';

import {DailiesGeneralComments} from './DailiesGeneralComments/DailiesGeneralComments';
import {DailiesReportHeader} from './DailiesReportHeader';
import {DailiesReportIssues} from './DailiesReportIssues';
import {DailyTasksWithNoReports} from './DailyTasksWithNoReports/DailyTasksWithNoReports';
import {DailyTasksWithReports} from './DailyTaskWithReport/DailyTaskWithReport';
import {useDailiesReports} from './hooks/useDailiesReports';
import {useFetchDailiesIssues} from './hooks/useFetchDailiesIssues';
import {WCDataTable} from './WCDataTable';

export function DailiesView() {
  const {t} = useTranslation('dailies');
  const {queryParams} = useFilterContext();
  const componentRef = useRef<HTMLDivElement>(null);
  const uiStore = useInjectStore<UIStoreType>(IOC_TYPES.UIStore);
  const selectedDay = formatDate(queryParams.schedEndFirst ?? '', 'LL');
  const {projectId} = useParams<{projectId: string}>();
  const hasSearchQuery = queryParams?.q;
  const {hasTasksListForCurrentDay, isLoading: areActivitiesLoading} = useDailiesReports();
  const {isLoading: areIssuesLoading} = useFetchDailiesIssues();

  useEffect(() => {
    uiStore.setDailiesPrintNodeRef(componentRef);
    return () => {
      uiStore.setDailiesPrintNodeRef({current: null});
    };
  }, [uiStore]);

  if (areActivitiesLoading || areIssuesLoading) {
    return (
      <section className="dailies-container">
        <div ref={componentRef} className="daily-report">
          <Loader />
        </div>
      </section>
    );
  }

  if (!hasTasksListForCurrentDay) {
    return (
      <section className="dailies-container">
        <div ref={componentRef} className="daily-report">
          <DailiesReportHeader selectedDay={selectedDay} />
          <DailiesReportIssues />
          <DailiesGeneralComments />
          <div className="no-data-message">
            <h2>
              {hasSearchQuery && !hasTasksListForCurrentDay
                ? t('dailies_report.no_search_results')
                : t('dailies_report.no_data_reported')}
            </h2>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="dailies-container">
      <div ref={componentRef} className="daily-report">
        <DailiesReportHeader selectedDay={selectedDay} />

        <VSpacer size="5" />

        <Accordion allowMultiple>
          {[
            {key: 'wc-data', label: <h4>Today&apos;s Crews</h4>, content: <WCDataTable projectId={projectId} />},
            {key: 'report-issues', label: <h4>Open Issues</h4>, content: <DailiesReportIssues />},
            {key: 'general-comments', label: <h4>General Comments</h4>, content: <DailiesGeneralComments />},
            {key: 'tasks-with-reports', label: <h4>Activities with Reports</h4>, content: <DailyTasksWithReports />},
            {key: 'tasks-no-reports', label: <h4>Activities with No Reports</h4>, content: <DailyTasksWithNoReports />},
          ].map(({key, label, content}) => (
            <AccordionItem
              key={key}
              header={
                <div className="accordion-heading">
                  {label}
                  <span className="accordion-heading-icon">
                    <Icon name={IconsMap['chevron-bottom']} />
                  </span>
                </div>
              }
              className="accordion-item"
            >
              {content}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
}
