/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/naming-convention */
import axios, {CancelToken, CancelTokenSource} from 'axios';
import {Effect, Queue, Schedule, Duration} from 'effect';

import {EffectError} from './errors';
import {calculateBatchParameters} from './helpers';

export type ApiResponse<T> = {
  data: T[];
  headers?: {
    'content-range'?: string;
    [key: string]: any;
  };
};

declare global {
  interface BatchLoadTracker {
    operationId: number;
    completed: Set<number>;
    inProgress: boolean;
    startTime: number;
    fnName: string;
    cancelTokenSource: CancelTokenSource;
    projectId: string;
  }

  interface Window {
    _batchLoads: {
      [fnName: string]: BatchLoadTracker;
    };
  }
}

export type BatchLoadConfig<T> = {
  batchFn: (
    offset: number,
    take: number,
    cancelToken: CancelToken,
  ) => Effect.Effect<ApiResponse<T>, EffectError, never>;
  fnName: string;
  projectId: string;
  batchSize?: number;
};

const effect = Effect.gen(function* () {
  const createBatchLoadEffect = <T>(config: BatchLoadConfig<T>): Effect.Effect<T[], EffectError, never> => {
    const {batchFn, fnName, projectId, batchSize} = config;
    const batchLoadId = Date.now();
    // eslint-disable-next-line import/no-named-as-default-member
    const cancelTokenSource = axios.CancelToken.source();

    if (typeof window._batchLoads === 'undefined') {
      window._batchLoads = {};
    }

    const progressTracker: BatchLoadTracker = {
      operationId: batchLoadId,
      completed: new Set<number>(),
      inProgress: true,
      startTime: performance.now(),
      fnName,
      cancelTokenSource,
      projectId,
    };

    const existingOperation = window._batchLoads[fnName];
    if (existingOperation?.projectId !== projectId && existingOperation?.inProgress) {
      existingOperation.cancelTokenSource?.cancel('Project changed');
      existingOperation.inProgress = false;
    }

    window._batchLoads[fnName] = progressTracker;

    return Effect.gen(function* (_) {
      try {
        const initialResponse = yield* _(batchFn(0, 1, cancelTokenSource.token));
        const contentRange = initialResponse.headers?.['content-range'];
        const total = contentRange
          ? parseInt(contentRange.match(/\d+-\d+\/(\d+)/)?.[1] ?? '0', 10)
          : initialResponse.data.length;

        if (total === 0) {
          return [];
        }

        const {
          batchSize: optimizedBatchSize,
          batchCount: numberOfBatches,
          concurrency: optimizedConcurrency,
        } = calculateBatchParameters(total, batchSize);

        const resultQueue = yield* _(Queue.unbounded<ApiResponse<T>>());

        const processBatch = (index: number) =>
          Effect.gen(function* (_) {
            const result = yield* _(
              batchFn(index * optimizedBatchSize, optimizedBatchSize, cancelTokenSource.token).pipe(
                Effect.retry(Schedule.exponential(Duration.seconds(2)).pipe(Schedule.compose(Schedule.recurs(3)))),
              ),
            );

            if (window._batchLoads[fnName]?.operationId === batchLoadId) {
              progressTracker.completed.add(index);
            }

            yield* _(Queue.offer(resultQueue, result));
            return result;
          });

        yield* _(
          Effect.forEach(
            Array.from({length: numberOfBatches}, (_, i) => i),
            processBatch,
            {concurrency: optimizedConcurrency},
          ),
        );

        const results = yield* _(
          Effect.forEach(Array.from({length: numberOfBatches}), () => Queue.take(resultQueue), {concurrency: 1}),
        );

        return results.flatMap((batch) => batch.data);
      } catch (error) {
        throw error;
      } finally {
        if (window._batchLoads[fnName]?.operationId === batchLoadId) {
          delete window._batchLoads[fnName];
        }
      }
    });
  };

  return {
    createBatchLoadEffect,
  };
});

export class BatchLoader extends Effect.Service<BatchLoader>()('BatchLoader', {
  effect,
}) {}
