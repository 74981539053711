import './styles.scss';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnDef,
  flexRender,
  SortingState,
  FilterFn,
} from '@tanstack/react-table';
import {useVirtualizer} from '@tanstack/react-virtual';
import debounce from 'lodash/debounce';
import {useState, useMemo, useRef, useCallback, useEffect} from 'react';
import {useQuery} from 'react-query';

import PorjectsService from 'api/projects';
import SearchInput from 'shared/components/CoreNewUI/SearchInput/SearchInput';
import SkeletonPreloader from 'shared/components/SkeletonPreloader';

interface WCDataRow {
  activeHours: number;
  wcCrewName: string;
  wcWorkerName: string;
  shiftDate: string;
  data: {
    'company.CompanyName': string;
    'worker.ExtWorkerID': number;
    'worker.CurrentDevice': string;
  };
}

const columns: ColumnDef<WCDataRow>[] = [
  {
    header: 'ID',
    accessorFn: (row) => row.data?.['worker.ExtWorkerID'],
    id: 'workerId',
    size: 80,
  },
  {
    header: 'Current Device',
    accessorFn: (row) => row.data?.['worker.CurrentDevice'],
    id: 'currentDevice',
  },
  {
    header: 'Company',
    accessorFn: (row) => row.data?.['company.CompanyName'],
    id: 'companyName',
  },
  {
    header: 'Crew Name',
    accessorKey: 'wcCrewName',
  },
  {
    header: 'Worker Name',
    accessorKey: 'wcWorkerName',
  },
  {
    header: 'Active Hours',
    accessorKey: 'activeHours',
    cell: (info) => {
      const value = info.getValue();
      return typeof value === 'number' ? value.toFixed(2) : '';
    },
  },
  {
    header: 'Shift Date',
    accessorKey: 'shiftDate',
    cell: (info) => info.getValue(),
  },
];

const calculateTotals = (rows: any[]) => {
  return rows.reduce(
    (acc, row) => ({
      workerCount: acc.workerCount + 1,
      activeHours: acc.activeHours + (row.original.activeHours || 0),
    }),
    {workerCount: 0, activeHours: 0},
  );
};

export const WCDataTable = ({projectId}: {projectId: string}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const {
    data: apiData = [],
    isLoading,
    error,
  } = useQuery<WCDataRow[]>('wakeCapData', () => PorjectsService.getWakeCapRollupData(projectId), {
    cacheTime: 10 * 60 * 1000, // 10mins
    staleTime: 9 * 60 * 1000, // 9mins
    refetchOnWindowFocus: false,
  });

  const customGlobalFilterFn: FilterFn<any> = (row, _columnId, filterValue) => {
    if (!filterValue) return true;

    const searchTerms = filterValue
      .split(',')
      .map((term: string) => term.trim())
      .filter(Boolean);

    if (searchTerms.length === 0) return true;

    const rowValues = {
      id: String(row.getValue('workerId') || ''),
      company: String(row.getValue('companyName') || '').toLowerCase(),
      device: String(row.getValue('currentDevice') || ''),
      workerName: String(row.getValue('wcWorkerName') || '').toLowerCase(),
      crewName: String(row.getValue('wcCrewName') || '').toLowerCase(),
    };

    return searchTerms.every((term: string) => {
      const searchWords = term.toLowerCase().split(' ').filter(Boolean);

      if (rowValues.id === term || rowValues.device === term) {
        return true;
      }

      return searchWords.every(
        (word: string) =>
          rowValues.company.includes(word) || rowValues.workerName.includes(word) || rowValues.crewName.includes(word),
      );
    });
  };

  const table = useReactTable({
    data: apiData,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableSorting: true,
    enableFilters: true,
    globalFilterFn: customGlobalFilterFn,
  });

  const {rows} = table.getRowModel();

  const totals = useMemo(() => calculateTotals(rows), [rows]);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 35,
    overscan: 10,
  });

  const debouncedSetGlobalFilter = useMemo(
    () =>
      debounce((value: string) => {
        setGlobalFilter(value);
      }, 500),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedSetGlobalFilter.cancel();
    };
  }, [debouncedSetGlobalFilter]);

  const [inputValue, setInputValue] = useState('');

  const handleSearchChange = useCallback(
    (value: string) => {
      setInputValue(value);
      debouncedSetGlobalFilter(value);
    },
    [debouncedSetGlobalFilter],
  );

  const virtualRows = rowVirtualizer.getVirtualItems();
  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start : 0;
  const paddingBottom =
    virtualRows.length > 0 ? rowVirtualizer.getTotalSize() - virtualRows[virtualRows.length - 1].end : 0;

  if (error) return <p>Error loading data</p>;

  return (
    <>
      <div className="search-container">
        <div className="spacer">
          <div className="table-summary">
            <div className="table-summary__item">
              <SkeletonPreloader when={isLoading} width={160}>
                <span className="table-summary__label">Total Workers:</span>
                <span className="table-summary__value">{` ${totals.workerCount}`}</span>
              </SkeletonPreloader>
            </div>
            <div className="table-summary__item">
              <SkeletonPreloader when={isLoading} width={180}>
                <span className="table-summary__label">Total Active Hours:</span>
                <span className="table-summary__value">{` ${totals.activeHours.toFixed(2)}`}</span>
              </SkeletonPreloader>
            </div>
          </div>
        </div>

        <div className="search-wrapper">
          <SkeletonPreloader when={isLoading} width={280} height={40}>
            <SearchInput
              name="tableSearch"
              value={inputValue}
              onChange={handleSearchChange}
              inputClassName="search-input"
              placeholder="Search by comma-separated values..."
            />
          </SkeletonPreloader>
        </div>
      </div>

      <div className="tables-container">
        <div className="issues-table-card">
          <div
            ref={tableContainerRef}
            className="table-scrollable-container"
            style={{
              height: '600px',
              overflow: 'auto',
            }}
          >
            <table className="issues-table">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="issues-table__header-row">
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="issues-table__header-cell"
                        onClick={!isLoading ? header.column.getToggleSortingHandler() : undefined}
                        style={{
                          cursor: isLoading ? 'default' : 'pointer',
                          width: header.column.getSize(),
                        }}
                      >
                        <div>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {!isLoading && (
                            <span className="sort-indicator">
                              {{
                                asc: ' ↑',
                                desc: ' ↓',
                              }[header.column.getIsSorted() as string] ?? null}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {isLoading ? (
                  Array.from({length: 20}).map((_, rowIndex) => (
                    <tr key={`skeleton-row-${rowIndex}`} className="issues-table__row">
                      {columns.map((column, colIndex) => (
                        <td
                          key={`skeleton-${rowIndex}-${colIndex}-${column.id || colIndex}`}
                          className="issues-table__cell"
                          style={{
                            width: column.size,
                          }}
                        >
                          <SkeletonPreloader when={true} height={20} className="skeleton-cell" />
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <>
                    {paddingTop > 0 && (
                      <tr>
                        <td style={{height: `${paddingTop}px`}} colSpan={columns.length} />
                      </tr>
                    )}
                    {virtualRows.map((virtualRow) => {
                      const row = rows[virtualRow.index];
                      return (
                        <tr key={row.id} className="issues-table__row">
                          {row.getVisibleCells().map((cell) => (
                            <td
                              key={cell.id}
                              className="issues-table__cell"
                              style={{
                                width: cell.column.getSize(),
                              }}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                    {paddingBottom > 0 && (
                      <tr>
                        <td style={{height: `${paddingBottom}px`}} colSpan={columns.length} />
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
