/* eslint-disable @typescript-eslint/naming-convention */
import {Dayjs} from 'dayjs';
import {Effect} from 'effect';

import {ConcurrentActivityIdsLoader} from 'services/Effect/ActivityIdsLoader';
import {EffectError} from 'services/Effect/errors';
import {runLoaderEffect} from 'services/Effect/helpers';
import {ConcurrentIssuesLoader} from 'services/Effect/IssuesLoader';
import {ConcurrentTaskLoader} from 'services/Effect/TasksLoader';
import {TaskFilterQuery} from 'shared/models/task/filter';
import {IssueModelRawDTO, TaskGanttModel, TaskListMinimalModel, TaskModelRawDTO} from 'shared/models/task/task';

import {LoadOptions} from '../types';

export type LoaderOptions = {
  name: string;
  projectId: string;
  extra?: Record<string, unknown>;
};

export async function loadActivityIds(options: LoadOptions) {
  const program = Effect.gen(function* () {
    const activityLoader = yield* ConcurrentActivityIdsLoader;
    const result = yield* activityLoader.loadActivityIds(options);
    return result as TaskListMinimalModel[];
  }).pipe(Effect.map((result) => result as TaskListMinimalModel[]));

  return runLoaderEffect<TaskListMinimalModel[], EffectError, ConcurrentActivityIdsLoader>(
    program,
    ConcurrentActivityIdsLoader.Default,
    {
      name: 'loadActivityIds',
      projectId: options.projectId,
      extra: {
        queryParams: options.queryParams,
        dataRange: options.dataRange,
      },
    },
  );
}

export async function loadAllTasks(projectId: string, loadDeleted: boolean) {
  const program = Effect.gen(function* () {
    const taskLoader = yield* ConcurrentTaskLoader;
    const result = yield* taskLoader.loadAllTasks(projectId, loadDeleted);
    return result;
  }).pipe(Effect.map((result) => result as TaskModelRawDTO[]));

  return runLoaderEffect<TaskModelRawDTO[], EffectError, ConcurrentTaskLoader>(program, ConcurrentTaskLoader.Default, {
    name: 'loadAllTasks',
    projectId,
  });
}

export async function loadIssues(options: LoadOptions) {
  const program = Effect.gen(function* () {
    const issuesLoader = yield* ConcurrentIssuesLoader;
    const result = yield* issuesLoader.loadIssues(options);
    return result;
  }).pipe(Effect.map((result) => result as IssueModelRawDTO[]));

  return runLoaderEffect<IssueModelRawDTO[], EffectError, ConcurrentIssuesLoader>(
    program,
    ConcurrentIssuesLoader.Default,
    {
      name: 'loadIssues',
      projectId: options.projectId,
      extra: {
        queryParams: options.queryParams,
        dataRange: options.dataRange,
      },
    },
  );
}

export async function fetchTasksByDate(projectId: string, begin: Dayjs, end: Dayjs) {
  const program = Effect.gen(function* () {
    const taskLoader = yield* ConcurrentTaskLoader;
    return yield* taskLoader.fetchTasksByDate(projectId, begin.toDate(), end.toDate());
  });

  return runLoaderEffect<TaskGanttModel[], EffectError, ConcurrentTaskLoader>(program, ConcurrentTaskLoader.Default, {
    name: 'fetchTasksByDate',
    projectId,
  });
}

export async function fetchBlockedTasks(projectId: string) {
  const program = Effect.gen(function* () {
    const taskLoader = yield* ConcurrentTaskLoader;
    return yield* taskLoader.fetchBlockedTasks(projectId);
  });

  return runLoaderEffect<TaskGanttModel[], EffectError, ConcurrentTaskLoader>(program, ConcurrentTaskLoader.Default, {
    name: 'fetchBlockedTasks',
    projectId,
  });
}

export async function loadDailiesIssues(projectId: string, queryParams: Partial<TaskFilterQuery>) {
  const program = Effect.gen(function* () {
    const issuesLoader = yield* ConcurrentIssuesLoader;
    return yield* issuesLoader.loadDailiesIssues({
      projectId,
      queryParams,
    });
  }).pipe(Effect.map((result) => result as IssueModelRawDTO[]));

  return runLoaderEffect<IssueModelRawDTO[], EffectError, ConcurrentIssuesLoader>(
    program,
    ConcurrentIssuesLoader.Default,
    {
      name: 'loadDailiesIssues',
      projectId,
      extra: {
        queryParams,
      },
    },
  );
}
