import {AxiosError, AxiosResponse} from 'axios';
import {camelize} from 'humps';

import {getApiResponseDataRange} from 'shared/helpers/common';
import {BasicApiError, DetailedApiError, GeneralizedApiError} from 'shared/models/apiError';

export function isAxiosError<T>(payload: unknown): payload is AxiosError<T> {
  // TODO: Upgrade to latest axios
  // Same implementation as axios.isAxiosError(payload) due to a bug in axios v0.21
  // https://github.com/axios/axios/issues/3815
  const error = payload as AxiosError<T>;
  return typeof error === 'object' && error.isAxiosError === true && error.response !== undefined;
}

function isErrorWithData(data: unknown): data is GeneralizedApiError {
  return (
    typeof data === 'string' ||
    (typeof data === 'object' && data !== null && ('detail' in data || 'message' in data || 'error' in data))
  );
}

function isBasicApiError(data: unknown): data is BasicApiError {
  return data && typeof data === 'object' && ('message' in data || 'error' in data);
}

function isDetailedApiError(data: unknown): data is DetailedApiError {
  return data && typeof data === 'object' && 'detail' in data;
}

export const UNEXPECTED_ERROR = 'Unexpected error';

type ExtractedError = Record<string, string> | string;

export function extractAxiosError(error: unknown, errorsMapping?: Record<string, string>): ExtractedError {
  if (isAxiosError<GeneralizedApiError>(error) && isErrorWithData(error.response.data)) {
    const errorData = error.response.data;

    // Directly return if data is a string
    if (typeof errorData === 'string') {
      return errorData;
    }

    // Handle DetailedApiError
    if (isDetailedApiError(errorData)) {
      if (typeof errorData.detail === 'string') {
        return errorData.detail;
      } else if (Array.isArray(errorData.detail)) {
        const errors = errorData.detail.reduce((prev, cur) => {
          const errorField = errorsMapping?.[cur.field] || camelize(cur.field);
          return {...prev, [errorField]: cur.error};
        }, {});
        return Object.keys(errors).length ? errors : UNEXPECTED_ERROR;
      }
    }

    // Handle BasicApiError
    if (isBasicApiError(errorData)) {
      return errorData.message || errorData.error || UNEXPECTED_ERROR;
    }
  }

  // Handle everything else
  return UNEXPECTED_ERROR;
}

export function stringifyAxiosError(error: unknown, useFirstError = false) {
  const extract = extractAxiosError(error);
  if (typeof extract === 'string') return extract;
  if (useFirstError) {
    return Object.values(extract)[0];
  }
  return Object.values(extract).join('\r\n');
}

export async function fetchAllBlocking<R>(request: (offset: number, take: number) => Promise<AxiosResponse<R[]>>) {
  return fetchAll(request, 100, null, null).promise;
}

function fetchAll<R>(
  request: (offset: number, take: number) => Promise<AxiosResponse<R[]>>,
  take = 100,
  handleFirstResponse: (data: R[]) => void | null,
  handleResponse: (data: R[]) => void | null,
) {
  // TODO: add axios abort controller
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let interrupt = false;
  const fetcher = async () => {
    const first = await request(0, take);
    handleFirstResponse && handleFirstResponse(first.data);
    const {total} = getApiResponseDataRange(first);
    const chunks = Math.ceil((total - take) / take);
    const requests = Array.from({length: chunks}).map((_, i) => request(i * take + take, take));
    const rest = await Promise.all(requests);
    const restData = rest.reduce((acc, cur) => acc.concat(cur.data), [] as R[]);
    handleResponse && handleResponse(restData);

    return first.data.concat(restData) as R[];
  };
  const cancel = () => {
    interrupt = true;
  };
  return {
    cancel,
    promise: fetcher(),
  };
}
