import {useEffect} from 'react';
import {useLocation} from 'react-router';

import {TasksLocationState} from 'modules/Tasks/types/location';
import {useProgressReportPopupContext} from 'shared/components/ProgressReportingPopup/ProgressReportPopupProvider';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

import {DailiesReportCard} from '../DailiesReportCard';
import {useDailiesReports} from '../hooks/useDailiesReports';

export function DailyTasksWithNoReports() {
  const {updateCurrentTaskId} = useProgressReportPopupContext();
  const {tasksWithNoReports, oskMap} = useDailiesReports();
  const location = useLocation<TasksLocationState>();
  const {mixpanel} = useAnalyticsService();

  useEffect(() => {
    if (location.state?.taskId) {
      const el = document.querySelector(`[data-task-id="${location.state.taskId}"]`);
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
        delete location.state.taskId;
      }
    }
  });

  return (
    <>
      {/* <h3 className="daily-report-report-title">{taskWithNoReportTitle}</h3> */}

      {tasksWithNoReports?.map((task) => (
        <DailiesReportCard key={task.id} taskId={task.id}>
          <DailiesReportCard.Header
            name={task.name}
            oskMap={oskMap.current}
            outlineSortKey={task.outlineSortKey}
            companyName={task.responsibleOrg?.group?.name}
            startDate={task.formatedStartDate}
            endDate={task.formatedEndDate}
            displayMode="minimal"
            handleOpenProgressReport={() => {
              updateCurrentTaskId(task.id);
              mixpanel.track(mixpanel.events.dailies.dailiesActivityAddReportPopup);
            }}
          />
          <DailiesReportCard.Content displayMode="minimal" taskId={task.id} />
          <DailiesReportCard.Comments taskId={task.id} comments={task.comments} />
        </DailiesReportCard>
      ))}
    </>
  );
}
